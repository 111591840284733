<template>
  <div>
    <CCardBody class="c-card-body">
      <CDataTable
        :items="equipmentParts"
        :fields="fields"
        hover
        border
        sorter
        :tableFilter = "{label: 'Поиск', placeholder: 'введите код'}"
      >
        <template #code="{ item }">
          <td class="td" style="width: 50px;">
            <router-link :to="`/equipment-parts/${item.code}`">
              {{ item.code }}
            </router-link>
          </td>
        </template>
        <template #description="{ item }">
          <td class="td" style="width:150px;">
          {{ item.description }}
          </td>
        </template>
        <template #model_preview="{ item }">
          <td style="width: 100px !important;">
            <img
              class="image-showcase"
              :src="item.model_preview"
              style="width: 100px"
            />
          </td>
        </template>
      </CDataTable>
    </CCardBody>
  </div>
</template>

<style>
.test {
  background-color: red;
}

.c-card-body {
  background-color: #fefefe;
}

table {
  font-family: "Lucida Sans Unicode", "Lucida Grande", Sans-Serif;
  font-size: 14px;
  background: white;
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

th {
  font-weight: normal;
  color: #039;
  border-bottom: 2px dashed #6678b1;
  padding: 10px 8px;
}

.td {
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

tr:hover .td {
  background: #e8edff;
}

.td {
  border-bottom: 0.5px dashed #ccc;
  color: #669;
  padding: 9px 8px;
  transition: 0.3s linear;
}

th:nth-child(-n + 3),
.td:nth-child(-n + 3) {
  text-align: left;
}

.item-right {
  text-align: right;
}

tr:nth-child(3) {
}
</style>

<script>
import { mapState, mapActions } from "vuex";

const fields = [
  {
    key: "code",
    label: "Код детали",
  },
  {
    key: "description",
    label: "Описание",
  },
  {
    key: "model_preview",
    label: "Превью",
  },
];

export default {
  name: "EquipmentParts",
  data() {
    return {
      fields,
    };
  },
  computed: {
    ...mapState({
      equipmentParts: (state) => state.equipmentParts.all,
    }),
  },
  created() {
    this["equipmentParts/getAll"]();
  },
  mounted() {
  },
  methods: {
    ...mapActions(["equipmentParts/getAll"]),
  },
};
</script>
